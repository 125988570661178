const fieldInForm2 = [
  {
    id: 1,
    key: "id_card",
  },
  {
    id: 2,
    key: "prefix",
  },
  {
    id: 3,
    key: "first_name_th",
  },
  {
    id: 4,
    key: "first_name_en",
  },
  {
    id: 5,
    key: "last_name_th",
  },
  {
    id: 6,
    key: "last_name_en",
  },
  {
    id: 7,
    key: "nickname",
  },
  {
    id: 8,
    key: "nickname_en",
  },

  {
    id: 9,
    key: "birthday",
  },
  {
    id: 10,
    key: "telephone",
  },
  {
    id: 11,
    key: "email",
  },

  {
    id: 12,
    key: "home_address",
  },
  {
    id: 13,
    key: "town",
  },
  {
    id: 14,
    key: "alley",
  },

  {
    id: 15,
    key: "road",
  },

  {
    id: 16,
    key: "subdistrict",
  },
  {
    id: 17,
    key: "district",
  },
  {
    id: 18,
    key: "province",
  },
  {
    id: 19,
    key: "zip_code",
  },
  {
    id: 21,
    key: "gender",
  },
  {
    id: 22,
    key: "optional_telephone",
  },
  {
    id: 23,
    key: "id_card_image",
  },
  {
    id: 24,
    key: "country",
  },

  {
    id: 27,
    key: "company_information",
  },
  {
    id: 28,
    key: "company_type",
  },
  {
    id: 29,
    key: "company_branch_type",
  },
  {
    id: 30,
    key: "country",
  },
  {
    id: 25,
    key: "passport_no",
  },
  {
    id: 32,
    key: "middle_name_en",
  },
  {
    id: 31,
    key: "middle_name_th",
  },
];

const profile_fields2 = {
  id_card: "",
  prefix: "",
  first_name_th: "",
  first_name_en: "",
  last_name_th: "",
  last_name_en: "",
  email: "",
  telephone: "",
  gender: "",
  birthday: "",
  birthday_day: "",
  birthday_month: "",
  birthday_year: "",
  nickname: "",
  nickname_en: "",
  optional_telephone: "",
  id_card_image: "",
  facebook: "",
  x: "",
  is_company: "",
  company_type: "",
  company_branch_type: "",
  passport_no: "",
  middle_name_th: "",
  middle_name_en: "",
};

const profile_fields3 = {
  country: "",
  home_address: "",
  town: "",
  alley: "",
  road: "",
  subdistrict: "",
  district: "",
  province: "",
  zip_code: "",
};
export { profile_fields2, fieldInForm2, profile_fields3 };
